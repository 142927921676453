.projectsHeader {
    font-size: large;
    text-align: center;
    cursor: pointer;
}

.projectsHeaderLink {
    font-size: large;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    display: flex;
    color: var(--font-color-main);
}

.projectCard {
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    max-width: 60em;
}

.projectCard .description {
    margin-right: 1em;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projectCard .description h3 {
    margin-top: 0;
}

.projectCard .mediaCarousel {
    width: 70%;
}

.projectCard .tags {
    display: flex;
    flex-wrap: wrap-reverse;
}

.projectCard .tags * {
    margin-right: 0.7em;
    margin-top: 0.7em;
    background-color: #333;
    background-attachment: fixed;
    border-radius: 1em;
    padding: 0.3em 1em 0.3em 1em;
    font-weight: bold;
}

.iframeWrapper {
    position: relative;
    padding-bottom: 56.25%;
}

.iframeWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.control-dots {
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .projectCard {
        display: block;
        max-width: auto;
    }
    .projectCard .description, .projectCard .mediaCarousel {
        width: auto;
    }

    .projectCard .description h3 {
        margin-top: 1em;
    }
}