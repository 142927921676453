:root
{
    --bg-bar: #333333;
    --line-width: -0.15em;
    
    --bg-color-main: #000;
    --bg-color-secondary: #151515;
    --bg-color-main-transparent: #0007;
    
    --font-color-main: #FFF;
    --font-color-secondary: #FFF;
    --font-color-deselected: #777;
    --font-color-selected: #2fd2ff;
    
    --gradient-main: linear-gradient(to right, rgb(239, 131, 187), orange, rgb(239, 131, 187));
    /*--gradient-main: linear-gradient(to right, rgb(239, 131, 187), rgb(168, 220, 255), rgb(239, 131, 187));*/
    --gradient-overlay: var(--gradient-overlay-dark);
    --gradient-overlay-bright: var(--gradient-overlay-bright-dark);
    
    --gradient-additive: var(--gradient-overlay), var(--gradient-main);
    --gradient-additive-bright: var(--gradient-overlay-bright), var(--gradient-main);

    --gradient-shadow: linear-gradient(to top, #000F, #0007), linear-gradient(to right, #000F, #0000, #000F), var(--gradient-main);
    
    --gradient-overlay-dark: linear-gradient(to top, #000, #000, #000, #000C);
    --gradient-overlay-light: linear-gradient(to top, #316F, #2234);
    
    --gradient-overlay-bright-dark: linear-gradient(to top, rgba(0, 28, 141, 0.908), #03F0);
    --gradient-overlay-bright-light: linear-gradient(to top, #77F, #77F0);
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');


@font-face
{
    font-family:"Poiret-One";
    src: url("./assets/fonts/Poiret_One/PoiretOne-Regular.ttf");
}

@font-face
{
    font-family:"Quicksand";
    src: url("./assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
}

body
{
    color: var(--font-color-secondary);
    background-color: var(--bg-color-main);
    font-family: 'DM Sans', sans-serif;
    
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

a
{
    color: var(--font-color-selected);
    text-decoration: none;
}

.desktopHeader {
    position: fixed;
    top: 0;
    width: calc(100% - 4em);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg-color-main);
    z-index: 10;
    padding: 1em 2em;
}

.desktopHeaderItem {
    margin-left: 1em;
    margin-right: 1em;
    color: var(--font-color-deselected);
}

.desktopHeaderItem:hover {
    color: var(--font-color-main);
}

.headerShadow {
    height: 30px;
    width: 100vw;
    background: var(--gradient-shadow);
    background-attachment: local;
    position: fixed;
    z-index: -10;
}

@keyframes fadeInSubpage {
    from {opacity: 0; padding-top: 100px;}
    to {opacity: 1; padding-top: 0;}
}

.subpage
{
    margin-top: 3em;
    width: calc(100% - 2em);
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 8em;
    animation: fadeInSubpage;
    animation-duration: 1s;
}

.innerBlackBox {
    border-radius: 0.8em;
    background-color: var(--bg-color-main);
    padding: 1em;
}

.animateToContentSize {
    transition: max-height 0.5s ease;
    overflow: hidden;
}

.noVerticalMargin {
    margin-top: 0;
    margin-bottom: 0;
}

.noTopMargin {
    margin-top: 0;
}

.noBottomMargin {
    margin-bottom: 0;
}

.gradientBox, .greyBox {
    border-radius: 1em;
    padding: 1em;
}

.gradientBox {
    background-attachment: fixed;
    background: var(--gradient-main);
}

.greyBox {
    background-color: var(--bg-color-secondary);
}

.greyOutlineBox {
    border-color: var(--bg-color-secondary);
    border-width: 0.2em;
    border-radius: 1.4em;
    padding: 1em;
    border-style: solid;
}

.borderBox {
    color: var(--font-color-main);
    position: relative;
    background: var(--bg-color-main);
    padding: 1em;
    border-radius: 1em;
}

.borderBox::before
{
    border-radius: 1.15em;
    background: var(--gradient-additive-bright);
    background-attachment: fixed;
    content: '';
    top: var(--line-width);
    left: var(--line-width);
    bottom: var(--line-width);
    right: var(--line-width);
    position: absolute;
    z-index: -1;
}

.gradientText, .desktopHeaderItem.selected
{
    background: var(--gradient-main);
}

.gradientTextAdditive
{
    background: var(--gradient-additive-bright);
}

.gradientText, .gradientTextAdditive, .desktopHeaderItem.selected
{
    color: #0000;
    background-attachment: fixed;
    -webkit-background-clip: text;
            background-clip: text;
}

.dividerLine
{
    background: var(--gradient-additive-bright);
    background-attachment: fixed;
    width: 100%;
    height: 0.15em;
}

@media screen and (max-width: 768px)
{
    .desktopHeader 
    {
        flex-direction: column;
    }

    .desktopHeader nav 
    {
        margin-top: 1em;
    }
}
