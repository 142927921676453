#aboutMeSubpage
{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.aboutMeHalf
{
    flex-basis: 50%;
    max-width: 27em;

    padding-left: 3em;
    padding-right: 3em;
}

.aboutMeLeftHalf
{
    text-align: center;
    margin-left: auto;
    margin-right: 0;
}

#headshot
{
    border-radius: 50%;
    width: 100%;
}

#specialties
{
    margin-top: 2em;
    justify-content: center;
    text-align: center;
    display:flex;
    flex-wrap: wrap;
}

@keyframes fadeInCodeSpecialty {
    from {opacity: 0;}
    to {opacity: 1;}
}

.codeSpecialty {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: fadeInCodeSpecialty;
    animation-duration: 1s;
    padding: 0.2em 0.3em;
    margin: 0.2em 0.5em;
}

@media screen and (max-width: 768px)
{
    #aboutMeSubpage
    {
        display:inline-block;
        text-align: center;
    }

    .aboutMeHalf
    {
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    #headshot
    {
        width: 65%;
    }
}