#timelineBar {
    position: fixed;
    margin-left: 0.6em;
    width: 0.2em;
    top: 0;
    bottom: 0;
    background: var(--gradient-additive-bright);
    background-attachment: fixed;
    z-index: -1;
}

.orgMarker {
    max-width: 50em;
    margin: 0 auto 4em;
    border-radius: 1.4em;
}

@keyframes fadeInAndUp {
    from {opacity: 0; margin-top: 4em;}
    to {opacity: 1; margin-top: 1em;}
}

.timelineElementMain {
    display: block;
    position: relative;
    margin-top: 1em;
    animation-name: fadeInAndUp;
    animation-duration: 1s;
}

.timelineHeader
{
    display: inline-block;
    position: relative;
}

.timelineHeader *
{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin-top: 0;
}

.timelineTitle {
    margin-top: 0;
}

.timelineBulletTitle {
    margin-top: 0;
    margin-right: 2em;
    text-align: left;
}

.timelineOrgImage {
    height: 4em;
    width: 4em;
    margin-right: 1em;
    padding-top: 0.35em;
}

.timelineDate
{
    color: var(--font-color-deselected);
}

.expandLabelText {
    color: var(--font-color-deselected);
    position: absolute;
    transition: opacity 0.5s ease;
}

.descriptionText {
    transition: opacity 0.5s ease;
}

.downloadResumeButton {
    display: flex;
    justify-content: flex-end;
    max-width: 50em;
    margin: 0 auto;
}