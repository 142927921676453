@keyframes slideInContactPanel {
    from { margin-top: 4em; };
    to { margin-top: 2em; };
}

.contactPanel {
    background: var(--gradient-main);
    background-attachment: fixed;
    border-radius: 1em;
    margin-top: 3em;
    animation-name: slideInContactPanel;
    animation-duration: 1s;
    padding: 0.23em;
    text-align: left;
}

.contactPanelInner {
    border-radius: 0.8em;
    background-color: #0008;
    padding: 1em;
}

.contactPanelTitle {
    margin-top: 0;
}

.phoneRow {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.phoneRow>.contactRow {
    width: calc(100% - 7em);
}

.contactRow {
    background-color: var(--bg-color-main);
    display: flex;
    padding: 1em;
    border-radius: 0.8em;
}

.contactRow>p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5em;
}

.contactRow>img {
    height: 2em;
}

.linkedinIcon {
    background-color: black;
    border-radius: 0.8em;
    height: 4em;
    width: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.linkedinIcon>img {
    height: 2.5em;
    width: 2.5em;
    cursor: pointer;
}